import React  from "react"
import { Link } from "gatsby"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"

import styles from "./madetoorder.module.scss"

const MadeToOrder1 = ({ location }) => {

  return (
    <>
      <SEO
        title="Made To Order - We're Getting Started"
        description="Made To Order"
        url={location.pathname}
      />

      <header className={styles.header}>
        <nav className={styles.nav1}>
          <Heading
            tag="h2"
            level="2"
            className={styles.navHeading}
          >
            Made to Order
          </Heading>
          <ul className={styles.navList}>
            <li>
              <Link to="/made-to-order/chapter-1" className={styles.navLinkActive}>1</Link>
            </li>
            <li>
              <Link to="/made-to-order/chapter-2" className={styles.navLinkNext}>2</Link>
            </li>
            <li>
              <Link to="/made-to-order/chapter-3" className={styles.navLinkNext}>3</Link>
            </li>
          </ul>
        </nav>
        <div className={styles.headingContainer}>
          <h3 className={styles.chapterHeading}>CHAPTER 1</h3>
          <Heading
            tag="h1"
            level="1"
            className={styles.heading}
          >
            We're Getting Started
          </Heading>
        </div>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase1_1_Desktop.gif?v=1615114402",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase1_1_Mobile_v2.gif?v=1615223456",
          }}
          altText="Chapter 1"
        />
      </header>

      <section className={styles.section}>
        <div>
          <p>Our team of craftsmen (and women!) have received the Tamara-approved sketch of your shoes from L.A. and the preparations are underway. The factory in Italy is currently sourcing the materials for your pair from the finest leather purveyors in the world — inspecting every inch to ensure that there will be zero flaws in what end up on your feet.</p>
        </div>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase1_2_1320x.jpg?v=1615114401",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase1_2_850x.jpg?v=1615114401",
          }}
          altText="Chapter 1 - Part 1"
        />
      </section>

      <section className={styles.section}>
        <div>
          <p>A little bit on the team making your shoes: Tamara has spent her decades-long career building relationships with the best generations-old factories, and therefore artisans, in Italy. Their other clients include names like Hermès, Chanel, and Vuitton, to name a few.</p>
        </div>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase1_3_1320x.jpg?v=1615114401",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase1_3_850x.jpg?v=1615114401",
          }}
          altText="Chapter 1 - Part 2"
        />
      </section>
    </>
  )
}

export default MadeToOrder1
